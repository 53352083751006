<template>
  <div class="block" @click="click" :class="(num === 1 ? 'bomb ' : '')+(blurred ? 'blur ' : '')" :id="id">
    <p :class="['', 'blue', 'green', 'red'][Math.min(getAround(), 3)]">{{getAround()}}</p>
  </div>
</template>

<script>
export default {
  name: "Block",
  props: {
    num: Number,
    x: Number,
    y: Number,
    id: Number
  },
  data(){
    let grid = this.$parent.grid;
    console.log(grid)
    return {
      grid,
      blurred: true
    }
  },
  methods: {
    getAround(){
      
      let me = {x: this.x, y: this.y}
      console.log(me)
      let toCheck = [
        {x: me.x-1, y: me.y-1},
        {x: me.x, y: me.y-1},
        {x: me.x+1, y: me.y-1},
        {x: me.x-1, y: me.y},
        {x: me.x+1, y: me.y},
        {x: me.x-1, y: me.y+1},
        {x: me.x, y: me.y+1},
        {x: me.x+1, y: me.y+1}
      ]
      let myTotal = 0
      for (let toc of toCheck){
        if (toc.y < 0 ||toc.x < 0 || toc.y >= this.grid.length || toc.x >= this.grid[0].length){
          continue;
        }
        toc = this.grid[toc.y][toc.x]
        if (toc.num === 1){
          myTotal++
        }
      }
      return myTotal
    },
    clickAround(){
      let me = {x: this.x, y: this.y}
      console.log(me)
      let toCheck = [
        {x: me.x-1, y: me.y-1},
        {x: me.x, y: me.y-1},
        {x: me.x+1, y: me.y-1},
        {x: me.x-1, y: me.y},
        {x: me.x+1, y: me.y},
        {x: me.x-1, y: me.y+1},
        {x: me.x, y: me.y+1},
        {x: me.x+1, y: me.y+1},
        {x: me.x, y: me.y}
      ]
      for (let toc of toCheck){
        if (toc.y < 0 ||toc.x < 0 || toc.y >= this.grid.length || toc.x >= this.grid[0].length){
          continue;
        }
        toc = this.grid[toc.y][toc.x]
        let selel = document.getElementById(toc.id)
        if (selel.classList.contains("blur")){
          console.log(selel, selel.classList)
          selel.click()
        }
      }
    },
    click(){
      this.blurred = false
      if (this.num === 1){
        setTimeout(()=>{
          alert("You lose!")
          if (confirm("Do you want to restart?")){
            window.location.reload()
          }
        },250)
      }
      if (this.getAround() === 0){
        setTimeout(this.clickAround, 20)
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.block {
  display: inline-flex;
  width: 2rem;
  height: 2rem;
  background-color: transparent;
  color: white;
  padding: 0px;
  margin: 0.1rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  user-select: none;
  border: none;
}
.bomb {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgNDggNDgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iMjQiIGN5PSIyNCIgcj0iMTgiLz48cGF0aCBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS13aWR0aD0iNiIgc3Ryb2tlPSIjMDAwIiBkPSJNOCA4bDMyIDMyTTggNDBMNDAgOE00IDI0aDQwTTI0IDR2NDAiLz48cGF0aCBkPSJNMTAgMjRhMTQgMTQgMCAwMTE0LTE0IiBzdHJva2U9IiM4NDg0ODQiIGZpbGw9Im5vbmUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIzIi8+PC9zdmc+");
  background-repeat: no-repeat;
  background-size: 100%;
}
.bomb p {
  opacity: 0;
}
.blur p{
  opacity: 0;
}
.blur {
  background-image: url("");
  background-color: gray;
  border: inset .3rem ;
}
p {
  display: inline-block;
  padding: 0px;
  margin: 0px;

  color: transparent;
  font-size: 1.4rem;

  font-family: 'MINE-SWEEPER', sans-serif;
  src: url("https://minesweeper.vercel.app/assets/fonts/MINE-SWEEPER.ttf");
  font-weight: 900;
}

.blue{
  color: blue;
}
.green{
  color: green;
}
.red{
  color: red;
}
* > .blank{
  border: none;
  background-color: darkgray;
}
</style>
