<template>
  <div id="app" @click="()=>{firstClick=false}">
    <div class="row" v-for="row of Array.from(grid.keys())" :key="row">
      <Block v-for="block in Array.from(grid[row].keys())" :key="grid[row][block].id" :id="grid[row][block].id" :num="grid[row][block].num" :y="row" :x="block"></Block>
      <br>
    </div>
    <!-- <Block :num="1" v-for=""></Block> -->
  </div>
</template>

<script>
import Block from "./components/Block.vue";

export default {
  name: "App",
  components: {
    Block
  },
  data(){
    let grid = this.getGrid(15,15)
    console.log(grid)
    return {
      grid,
      firstClick: true
    }
  },
  methods: {
    arrayTimes(count, method){
      let n = []
      for (let i in [...Array(count)]){
        n.push(method())
        i;
      }
      return n;
    },
    realRandom(min, max){
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    random(min=0,max=10){
      return {num: this.realRandom(min, max) > 9 ? 1 : 0, id: this.realRandom(1_000_000_000,9_999_999_999)};
    },
    getGrid(x,y){
      return this.arrayTimes(y, this.arrayTimes.bind(null, x, this.random))
    },
    restart(){
      this.grid = this.getGrid(15,15);
    }
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Open+Sans&display=swap');

* {
  box-sizing: border-box;
}

.row{
  display: block;
  background-color: #323232;
  width: fit-content;
}
</style>
